import "./styles.scss"

import React from "react"

const Content = () => {
  return (
    <section className="privacy-policy-content">
      <div className="container-fluid">
        <div className="row">
          <div className="col-xl-9">
            <h3>Regulamin Serwisu z Polityką Prywatności i Polityką Cookies</h3>
            <p>
              Niniejszy Regulamin określa zasady i warunki świadczenia Marshall
              Real Estate Sp. z o.o. 00-682 Warszawa, ul. Uznamska 12/5 , usług
              drogą elektroniczną w serwisie marshallre.pl
            </p>
            <p>Podstawy Prawne niniejszego regulaminu:</p>
            <p>
              Prawo telekomunikacyjne: ustawa z dnia 16 lipca 2004 roku Prawo
              telekomunikacyjne ze zm. (Dz.U.2019.0.2460); Ustawa Śude: ustawa z
              dnia 18 lipca 2002 roku o świadczeniu usług drogą elektroniczną ze
              zm. (Dz.U.2020.0.344) ; Rodo: Rozporządzenie Parlamentu
              Europejskiego i Rady (EU) 2016/679 z dnia 27 kwietnia 2016 w
              sprawie ochrony osób fizycznych w związku z przetwarzaniem danych
              osobowych i w sprawie swobodnego przepływu takich danych oraz
              uchylenia dyrektywy 95/46/WE oraz treścią art. 13 ust 1 i 2 Ustawa
              ODO: ustawa z dnia 24 maja 2018 o ochronie danych osobowych (Dz.
              U. 2018 poz. 1000 ze zm.).
            </p>

            <h4>§1 Postanowienia ogólne i korzystanie z Serwisu</h4>
            <ul>
              <li>
                Wszelkie prawa do Serwisu, w tym majątkowe prawa autorskie,
                prawa własności intelektualnej do jego nazwy, jego domeny
                internetowej, a także do wzorców, formularzy, logotypów
                zamieszczanych na Stronie Serwisu (za wyjątkiem logotypów i
                zdjęć prezentowanych w Serwisie w celach prezentacji towarów, do
                których to prawa autorskie należą do podmiotów trzecich) należą
                do MARSHALL REAL ESTATE, a korzystanie z nich może następować
                wyłącznie w sposób określony i zgodny z Regulaminem oraz za
                zgodą MARSHALL REAL ESTATE wyrażoną na piśmie.
              </li>
              <li>
                W związku z udostępnianiem zawartości serwisu internetowego www.
                informujemy, iż stosujemy tzw. cookies – to jest informacje
                zapisywane na urządzeniu końcowym użytkownika, które następnie
                serwery mogą odczytać przy połączeniu się z tego urządzenia
                końcowego. Pliki cookies (tak zwane “ciasteczka”) stanowią dane
                – w szczególności pliki tekstowe, które przechowywane są w
                urządzeniu końcowym użytkownika naszego. Cookies zawierają nazwę
                domeny serwisu internetowego, z którego pochodzą, czas
                przechowywania ich na urządzeniu końcowym oraz unikalny numer.
              </li>
              <li>Pliki cookies wykorzystywane są w następujących celach:</li>
              <ul>
                <li>
                  dostosowania zawartości serwisu internetowego do preferencji
                  użytkownika oraz optymalizacji korzystania z tego serwisu. W
                  szczególności pliki te pozwalają rozpoznać urządzenie
                  użytkownika serwisu internetowego i odpowiednio wyświetlić
                  stronę internetową, dostosowaną do jego indywidualnych
                  potrzeb.
                </li>
                <li>
                  tworzenia statystyk, które pomagają zrozumieć, w jaki sposób
                  użytkownicy serwisu korzystają ze stron internetowych, co
                  pozwala na ulepszanie ich struktury i zawartości.
                </li>
              </ul>
              <li>
                W ramach Serwisu stosowane są dwa zasadnicze rodzaje plików
                cookies: „sesyjne” (session cookies) oraz „stałe” (persistent
                cookies). Cookies „sesyjne” są plikami tymczasowymi, które
                przechowywane są w urządzeniu końcowym Użytkownika do czasu
                wylogowania, opuszczenia strony internetowej lub wyłączenia
                oprogramowania (przeglądarki internetowej). „Stałe” pliki
                cookies przechowywane są w urządzeniu końcowym Użytkownika przez
                czas określony w parametrach plików cookies lub do czasu ich
                usunięcia przez Użytkownika.
              </li>
              <li>
                W ramach Serwisu stosowane są następujące rodzaje plików
                cookies:
              </li>
              <ul>
                <li>
                  „niezbędne” pliki cookies, umożliwiające korzystanie z usług
                  dostępnych w ramach Serwisu, np. uwierzytelniające pliki
                  cookies wykorzystywane do usług wymagających uwierzytelniania
                  w ramach Serwisu, pisele serwisów społecznościowych i google
                  maps.
                </li>
                <li>
                  pliki cookies służące do zapewnienia bezpieczeństwa, np.
                  wykorzystywane do wykrywania nadużyć w zakresie
                  uwierzytelniania w ramach Serwisu;
                </li>
                <li>
                  „wydajnościowe” pliki cookies, umożliwiające zbieranie
                  informacji o sposobie korzystania ze stron internetowych
                  Serwisu; google analitycs
                </li>
                <li>
                  „funkcjonalne” pliki cookies, umożliwiające „zapamiętanie”
                  wybranych przez Użytkownika ustawień i personalizację
                  interfejsu Użytkownika, np. w zakresie wybranego języka lub
                  regionu, z którego pochodzi Użytkownik, rozmiaru czcionki,
                  wyglądu strony internetowej itp.;
                </li>
                <li>
                  „reklamowe” pliki cookies, umożliwiające dostarczanie
                  Użytkownikom treści reklamowych bardziej dostosowanych do ich
                  zainteresowań.
                </li>
              </ul>
              <li>
                W wielu przypadkach oprogramowanie służące do przeglądania stron
                internetowych (przeglądarka internetowa) domyślnie dopuszcza
                przechowywanie plików cookies w urządzeniu końcowym Użytkownika.
                Użytkownicy Serwisu mogą dokonać w każdym czasie zmiany ustawień
                dotyczących plików cookies. Ustawienia te mogą zostać zmienione
                w szczególności w taki sposób, aby blokować automatyczną obsługę
                plików cookies w ustawieniach przeglądarki internetowej bądź
                informować o ich każdorazowym zamieszczeniu w urządzeniu
                Użytkownika Serwisu. Szczegółowe informacje o możliwości i
                sposobach obsługi plików cookies dostępne są w ustawieniach
                oprogramowania (przeglądarki internetowej). Niedokonanie zmiany
                ustawień w zakresie cookies oznacza, że będą one zamieszczone w
                urządzeniu końcowym użytkownika, a tym samym będziemy
                przechowywać informacje w urządzeniu końcowym użytkownika i
                uzyskiwać dostęp do tych informacji.
              </li>
              <li>
                Wyłączenie stosowania plików cookies może spowodować pewne
                utrudnienia podczas korzystania z niektórych funkcji naszego
                serwisu internetowego. Wyłączenie opcji zapisywania cookies na
                urządzeniu końcowym nie spowoduje natomiast całkowitego braku
                możliwości czytania lub oglądania treści zamieszczanych w naszym
                serwisie internetowym.
              </li>
              <li>
                Pliki cookies mogą być zapisywane w urządzeniu końcowym
                użytkownika serwisu internetowego, a następnie wykorzystywane
                przez współpracujących z serwisem reklamodawców, firmy badawcze
                oraz dostawców aplikacji multimedialnych.
              </li>
              <li>
                Więcej informacji na temat plików cookies dostępnych w sekcji
                „Pomoc” w menu przeglądarki internetowej.
              </li>
              <li>
                Informujemy, iż MARSHALL REAL ESTATE dołoży wszelkich starań,
                aby korzystanie z naszego serwisu internetowego było możliwe dla
                użytkowników Internetu z użyciem wszystkich popularnych
                przeglądarek internetowych, systemów operacyjnych, typów
                urządzeń oraz typów połączeń internetowych. `Minimalne wymagania
                techniczne umożliwiające korzystanie ze Serwisu to przeglądarka
                internetowa w wersji co najmniej Internet Explorer 11 lub Chrome
                39 lub FireFox 34 lub Opera 26 lub Safari 5 lub nowszych, z
                włączoną obsługą języka Javascript, akceptująca pliki typu
                „cookies” oraz łącze internetowe o przepustowości co najmniej
                256 kbit/s. Strona Serwisu jest zoptymalizowana dla minimalnej
                rozdzielczości ekranu 1024x768 pikseli
              </li>
              <li>
                Zakazane jest dostarczanie przez Użytkownika treści o
                charakterze bezprawnym oraz wykorzystywanie przez niego, Strony
                Internetowej lub usług nieodpłatnych świadczonych przez MARSHALL
                REAL ESTATE, w sposób sprzeczny z prawem, dobrymi obyczajami lub
                naruszający dobra osobiste osób trzecich.
              </li>
              <li>
                MARSHALL REAL ESTATE oświadcza, iż publiczny charakter sieci
                Internet i korzystanie z usług świadczonych drogą elektroniczną
                wiązać może się z zagrożeniem pozyskania i modyfikowania danych
                Użytkowników przez osoby nieuprawnione, dlatego Użytkownicy
                powinni stosować właściwe środki techniczne, które zminimalizują
                wskazane wyżej zagrożenia. W szczególności powinni stosować
                programy antywirusowe i chroniące tożsamość korzystających z
                sieci Internet.
              </li>
              <li>
                Z serwisu przeznaczony jest dla osób powyżej 16 roku życia.
              </li>
            </ul>

            <h4>§2 Wyłączenie odpowiedzialności</h4>
            <ul>
              <li>
                Marshall Real Estate dokłada wszelkich starań w celu zapewnienia
                aktualności i odpowiedniej jakości materiałów i wiadomości
                prezentowanych na Stronie.
              </li>
              <li>
                Dane i informacje zawarte na Stronie są publikowane wyłącznie do
                celów informacyjnych o Marshall Real Estate i produktach, które
                znajdują się w jego ofercie. Nie stanowią one oferty w myśl
                przepisów Ustawa z dnia 23 kwietnia 1964 r. – Kodeks cywilny
                (Dz.U. 1964 nr 16 poz. 93), jak również nie zmierzają do
                zawarcia, zmiany lub rozwiązania jakichkolwiek stosunków
                prawnych.
              </li>
              <li>
                Marshall Real Estate dołoży wszelkich starań w celu zapewnienia
                poprawnego działania Strony jednakże zastrzega, iż nie ponosi
                odpowiedzialności za szkody wynikające z wadliwego
                funkcjonowania Strony.
              </li>
              <li>
                Niniejsza Strona zawiera łącza do innych stron internetowych,
                które zamieszczone zostały wyłącznie dla wygody i informacji
                Użytkowników. Informuje się Użytkowników, iż witryny, do których
                prowadzą, mogą mieć własne regulaminy, z którymi zaleca się
                zapoznać podczas ich przeglądania. Marshall Real Estate
                wskazuje, iż nie ponosi on odpowiedzialności za treść takich
                witryn, produkty i usługi oferowane za ich pośrednictwem oraz
                inne ich wykorzystanie.
              </li>
            </ul>

            <h4>§3 Usługi nieodpłatne serwisu</h4>
            <ul>
              <li>
                MARSHALL REAL ESTATE świadczy na rzecz Użytkowników, drogą
                elektroniczną usługi nieodpłatne:
              </li>
              <ul>
                <li>
                  umożliwienie dostępu do treści zamieszczonych w Serwisie,
                </li>
                <li>
                  Kontakt przez Zakładkę Strona Główna – Zostaw kontakt, aby
                  otrzymać ofertę. Użytkownik podając swoje dane : adres e-mail,
                  imię, nazwisko, telefon prosi o przesłanie oferty
                  Administratora. Odpowiedź na zadane pytanie Użytkownik
                  otrzymuje niezwłocznie, podany adres poczty elektronicznej.
                </li>
                <li>
                  Kontakt przez zakładkę – Użytkownik : A ty jakiej
                  nieruchomości szukasz? Użytkownik zostawiając swoje dane
                  osobowe, Imię nazwisko , e mail, numer telefonu prosi o
                  kontakt z ekspertem, który przedstawi ofertę spełniającą
                  oczekiwania Użytkownika. Użytkownik ma możliwość zaznaczenia
                  kilku dobrowolnych okienek w celu wyrażenia zgody na
                  przetwarzanie danych osobowych w celach marketingowych tj.
                  wysyłanie ofert Administratora i firm współpracujących, oraz
                  przesyłania ofert pocztą elektroniczną lub/i telefonicznie.
                  Użytkownik może wycofać zgodę w każdej chwili wysyłając mail
                  na adres: kontakt@marshallre.pl
                </li>
                <li>
                  Przesłania wiadomości przez zakładkę – Developer – Poznaj nasz
                  bliżej : Zakładka przeznaczona jest dla Developerów chcących
                  nawiązać współpracę. Użytkownik zostawiając swoje dane
                  osobowe, Imię nazwisko , e mail. Użytkownik ma możliwość
                  zaznaczenia kilku dobrowolnych okienek w celu wyrażenia zgody
                  na przetwarzanie danych osobowych w celach marketingowych tj.
                  wysyłanie ofert Administratora i firm współpracujących, oraz
                  przesyłania ofert pocztą elektroniczną lub/i telefonicznie.
                  Użytkownik może wycofać zgodę w każdej chwili wysyłając mail
                  na adres: kontakt@marshallre.pl
                </li>
                <li>
                  Kontakt przez zakładkę – Kariera – Dołącz do naszego zespołu.
                  Zakładka przeznaczona jest dla pracowników lub firm chcących
                  dołączyć do zespołu Administratora. Użytkownik zostawiając
                  swoje dane osobowe, Imię nazwisko , e mail lub/i załączając
                  plik np. CV, aplikuje o pracę u Administratora.
                </li>
                <li>
                  Pliki CV przechowujemy 6 miesięcy po zakończeniu procesu
                  rekrutacji. Użytkownik ma możliwość zaznaczenia kilku
                  dobrowolnych okienek w celu wyrażenia zgody na przetwarzanie
                  danych osobowych w celach marketingowych tj. wysyłanie ofert
                  Administratora i firm współpracujących, oraz przesyłania ofert
                  pocztą elektroniczną lub/i telefonicznie. Użytkownik może
                  wycofać zgodę w każdej chwili wysyłając mail na adres:
                  kontakt@marshallre.pl
                </li>
              </ul>
              <li>
                Usługi wskazane ust. 1 świadczone są 7 dni w tygodniu, 24
                godziny na dobę. Administrator dokłada starań, aby zrealizować
                usługi wskazanych w pkt. 1 bez zbędnej zwłoki.
              </li>
              <li>
                Użytkownik zawiera umowę o świadczenie usług drogą elektroniczną
                wymienionych w § 3 pkt.1aw momencie wejścia na stronę Serwisu.
                Umowa rozwiązuje się w momencie jego opuszczenia.
              </li>
              <li>
                Użytkownik zawiera umowę o świadczenie usług drogą elektroniczną
                wymienionych w § 3 pkt.1 b-ew momencie wysłania zapytania. Umowa
                rozwiązuje się w momencie jej zrealizowania.
              </li>
              <li>
                Użytkownik zobowiązuje się, że bez uprzedniej pisemnej zgody
                MARSHALL REAL ESTATE nie będzie wykorzystywać kopii bądź części
                niniejszej Strony lub oznaczeń zawartych w jej treści w celach
                komercyjnych.
              </li>
              <li>
                Każdy Użytkownik zobowiązuje się do nie podejmowania działań
                mających na celu zakłócenie poprawnej pracy Strony czy uzyskanie
                przysługujących mu praw dostępu.
              </li>
              <li>
                Użytkownik Strony jest zobowiązany do korzystania z serwisu
                zgodnie z jej przeznaczeniem.
              </li>
              <li>
                Użytkownik Strony zobowiązuje się do korzystania z treści
                udostępnianych na Stronie zgodnie z prawem oraz niniejszym
                Regulaminem
              </li>
            </ul>

            <h4>§4 Ochrona danych osobowych przetwarzanych w Serwisie</h4>
            <ul>
              <li>
                Sprzedawca chroni przekazane mu dane osobowe oraz dokłada
                wszelkich starań w celu zabezpieczenia ich przed nieuprawnionym
                dostępem lub wykorzystaniem przez osoby nieupoważnione.
              </li>
              <li>ska 12 lok. 5 , 00-682 Warszawa.</li>
              <li>
                Dane kontaktowe w sprawie danych osobowych:
                kontakt@marshallre.pl.
              </li>
              <li>
                Cel, podstawa przetwarzania danych osobowych oraz czas ich
                przechowywania:
              </li>
              <ul>
                <li>
                  zawarcia i wykonania umowy – (podstawa prawna: art. 6 ust.1 b
                  RODO – wykonanie umowy) przez czas trwania umowy i rozliczeń
                  po jej zakończeniu,
                </li>
                <li>
                  wykonania ciążących na nas obowiązków prawnych (podstawa
                  prawna: art.6 ust.1c RODO- obowiązek prawny) np. wystawianie i
                  przechowywanie dokumentów księgowych, rozpatrywanie reklamacji
                  przez czas, w którym przepisy nakazują przechowywanie
                  dokumentów np. przepisy podatkowe,
                </li>
                <li>
                  dochodzenia roszczeń (podstawa prawna: art.6 ust.1fc RODO-
                  prawnie uzasadniony interes Administratora) – przez okres, w
                  którym przedawniają się roszczenia wynikające z umowy,
                </li>
                <li>
                  w celu tworzenia analiz , statystyk na potrzeby wewnętrzne
                  Administratora ( podstawa prawna: art.6 ust.1 f RODO- prawnie
                  uzasadniony interes Administratora) przez okres lat 5,
                </li>
                <li>
                  marketingu bezpośredniego ( podstawa prawna: art.6 ust.1 f
                  RODO- prawnie uzasadniony interes Administratora) przez okres
                  lat 5, lub w przypadku zgody na marketing do czasu jej
                  wycofania,
                </li>
                <li>
                  odpowiedzi na zapytania Klienta (podstawa prawna: art. 6 ust.1
                  b RODO – wykonanie umowy) przez okres lat 5
                </li>
                <li>
                  w innych celach na podstawie zgody osoby (podstawa prawna:
                  art. 6 ust.1 a RODO – zgoda osoby) przez czas określony w
                  informacji /regulaminie podczas uzyskiwania zgody.
                </li>
              </ul>
              <li>Rodzaj i sposób pozyskiwanych danych:</li>
              <li>
                Pozyskujemy tylko dane niezbędne do realizacji celu
                przetwarzania. Przekazanie danych osobowych jest dobrowolne.
                Jednakże nie podanie danych może powodować niemożliwość
                skorzystania z konkretnej usługi lub zawarcie umowy.
              </li>
              <li>
                Jeżeli do przetwarzania danych w konkretnym celu niezbędna jest
                Państwa zgoda np. na potrzeby akcji promocyjnych, konkursów,
                wysyłania informacji handlowych drogą elektroniczną mogą Państwo
                w każdej chwili swoją zgodę wycofać przez zgłoszenie na adres
                e-mail podany w pkt. 2 lub postepowanie zgodne z instrukcją
                podczas udzielania zgody.
              </li>
              <li>Przekazywanie danych</li>
              <li>
                Dane osobowe przekazujemy podmiotom przetwarzającym w naszym
                imieniu:
              </li>
              <ul>
                <li>
                  obsługującym nasz system informatyczny, świadczącym umowy
                  hostingu,
                </li>
                <li>
                  podwykonawcom wspierającym Administratora w procesach obsługi
                  działalności (firmy księgowe, doradcze, audytowe, wsparcia
                  procesów administracyjnych i technicznych, Kancelarii
                  prawnych, obsługujących wierzytelności),
                </li>
                <li>
                  firmom kurierskim, firmom obsługującym płatności –banki,
                  instytucje płatnicze.
                </li>
              </ul>
              <li>Prawa osób, których dane przetwarzamy (Art. 16-21 RODO)</li>
              <li>
                Mają Państwo prawo do złożenia wniosku (pocztą, pocztą
                elektroniczną – adres w pkt.2) o:
              </li>
              <ul>
                <li>sprostowanie danych</li>
                <li>usunięcie danych – prawo do bycia zapomnianym</li>
                <li>
                  ograniczenie przetwarzania – wstrzymanie przetwarzania lub
                  nieusuwanie danych
                </li>
                <li>
                  dostęp do danych – informację o przetwarzanych danych oraz o
                  ich kopię
                </li>
                <li>
                  przeniesienie danych (w zakresie określonym w Art. 20 RODO)
                </li>
                <li>
                  prawo sprzeciwu – np. przetwarzania w celu marketingu
                  bezpośredniego. Złożenie takiego żądania oznacza, ze nie
                  będziemy przetwarzać Państwa danych w tym celu.
                </li>
              </ul>
              <li>
                Mają Państwo prawo wnieść skargę do Prezesa Urzędu Ochrony
                Danych osobowych, jeżeli uważają Państwo, że przetwarzanie
                Państwa danych osobowych narusza przepisy prawa.
              </li>
            </ul>

            <h4>§5 Postanowienia końcowe</h4>
            <ul>
              <li>
                Treść niniejszego Regulaminu może zostać utrwalona poprzez
                wydrukowanie, zapisanie na nośniku lub pobranie w każdej chwili
                ze Strony Internetowej Serwisu.
              </li>
              <li>
                MARSHALL REAL ESTATE zastrzega sobie prawo zmiany niniejszego
                Regulaminu. Zmiana Regulaminu wchodzi w życie z jest zawiadomić
                o tym fakcie MARSHALL REAL ESTATE.
              </li>
              <li>Regulamin wchodzi w życie z dniem 10 lipca 2020</li>
            </ul>
          </div>
        </div>
      </div>
    </section>
  )
}

export default Content
