import React from "react"

import Layout from "components/Layout"
import Breadcrumbs from "components/Breadcrumbs"
import PageHeader from "components/PageHeader"

import { Content } from "page_components/privacy-policy"

const PrivacyPolicy = () => {
  const title = "Polityka prywatności"
  return (
    <Layout
      seo={{
        title: title,
        description:
          "Inwestycje w apartamenty premium. Zarabiaj inwestując w apartamenty inwestycyjne, hotelowe, komercyjne.",
      }}
    >
      <Breadcrumbs title={title} />
      <PageHeader title={title} />
      <Content />
    </Layout>
  )
}

export default PrivacyPolicy
